import React from 'react'

export default function Loader() {
    return (
        <>
            <style>
                {
                    `
                    .lds-hourglass {
                        background-color: rgba(255,255,255,0.7);
                        z-index: 10;
                    }
                    .lds-hourglass:after {
                        content: " ";
                        display: block;
                        box-sizing: border-box;
                        border-radius: 50%;
                        border: 32px solid red;
                        border-color: #B70000 transparent #B70000 transparent;
                        animation: lds-hourglass 1.2s infinite;
                    }
                    @keyframes lds-hourglass {
                        0% {
                        transform: rotate(0);
                        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
                        }
                        50% {
                        transform: rotate(900deg);
                        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                        }
                        100% {
                        transform: rotate(1800deg);
                        }
                    }
                    `
                }
            </style>

            <div className='lds-hourglass'></div>
        </>
    )
}
