import React, { Suspense, lazy } from 'react';
import './styles/App.css';
import './styles/content-styles.css';
import {
    Routes,
    Route
} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Loader from './utils/Loader';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const AdminLayout = lazy(() => import('./components/layouts/AdminLayout'));
const PageIndex = lazy(() => import('./pages/er-pages/PageIndex'));
const PageCreate = lazy(() => import('./pages/er-pages/PageCreate'));
const PageEdit = lazy(() => import('./pages/er-pages/PageEdit'));
const PageCreateHome = lazy(() => import('./pages/er-pages/PageCreateHome'));
const ScrollToTop = lazy(() => import('./utils/ScrollToTop'));
const PortalIndex = lazy(() => import('./pages/physicians-portal/PortalIndex'));
const PortalCreate = lazy(() => import('./pages/physicians-portal/PortalCreate'));
const PortalEdit = lazy(() => import('./pages/physicians-portal/PortalEdit'));
const LocationIndex = lazy(() => import('./pages/locations/LocationIndex'));
const LocationCreate = lazy(() => import('./pages/locations/LocationCreate'));
const LocationEdit = lazy(() => import('./pages/locations/LocationEdit'));
const SubLocationIndex = lazy(() => import('./pages/locations-sub/SubLocationIndex'));
const SubLocationCreate = lazy(() => import('./pages/locations-sub/SubLocationCreate'));
const SubLocationEdit = lazy(() => import('./pages/locations-sub/SubLocationEdit'));
const LocationContent = lazy(() => import('./pages/locations/LocationContent'));
const SymptomIndex = lazy(() => import('./pages/symptoms/SymptomIndex'));
const SymptomCreate = lazy(() => import('./pages/symptoms/SymptomCreate'));
const BlogIndex = lazy(() => import('./pages/blogs/BlogIndex'));
const BlogCreate = lazy(() => import('./pages/blogs/BlogCreate'));
const BlogEdit = lazy(() => import('./pages/blogs/BlogEdit'));
const CategoryIndex = lazy(() => import('./pages/blogs/CategoryIndex'));
const AwardIndex = lazy(() => import('./pages/awards/AwardIndex'));
const AwardCreate = lazy(() => import('./pages/awards/AwardCreate'));
const VideoCreate = lazy(() => import('./pages/videos/VideoCreate'));
const VideoIndex = lazy(() => import('./pages/videos/VideoIndex'));
const JobPostIndex = lazy(() => import('./pages/job-posts/JobPostIndex'));
const JobPostCreate = lazy(() => import('./pages/job-posts/JobPostCreate'));
const JobPostActive = lazy(() => import('./pages/job-posts/JobPostActive'));
const SymptomEdit = lazy(() => import('./pages/symptoms/SymptomEdit'));
const AwardEdit = lazy(() => import('./pages/awards/AwardEdit'));
const VideoEdit = lazy(() => import('./pages/videos/VideoEdit'));
const JobPostEdit = lazy(() => import('./pages/job-posts/JobPostEdit'));
const ScholarshipIndex = lazy(() => import('./pages/scholarships/ScholarshipIndex'));
const ScholarshipCreate = lazy(() => import('./pages/scholarships/ScholarshipCreate'));
const ScholarshipEdit = lazy(() => import('./pages/scholarships/ScholarshipEdit'));
const EmailEdit = lazy(() => import('./pages/emails/EmailEdit'));
const EmailCreate = lazy(() => import('./pages/emails/EmailCreate'));
const EmailIndex = lazy(() => import('./pages/emails/EmailIndex'));
const EmailJobLocationList = lazy(() => import('./pages/emails/EmailJobLocationList'));
const EmailEntry = lazy(() => import('./pages/emails/EmailEntry'));
const DocumentIndex = lazy(() => import('./pages/media/DocumentIndex'));
const ImageIndex = lazy(() => import('./pages/media/ImageIndex'));
const GlobalTags = lazy(() => import('./pages/navigations/GlobalTags'));
const NavMainMenu = lazy(() => import('./pages/navigations/NavMainMenu'));
const NavTopMenu = lazy(() => import('./pages/navigations/NavTopMenu'));
const NavBottomMenu = lazy(() => import('./pages/navigations/NavBottomMenu'));
const NavFooter = lazy(() => import('./pages/navigations/NavFooter'));
const NavLocationSidebar = lazy(() => import('./pages/navigations/NavLocationSidebar'));
const NavSymptomSidebar = lazy(() => import('./pages/navigations/NavSymptomSidebar'));
const NavMobileLayout = lazy(() => import('./pages/navigations/NavMobileLayout'));
const ERService = lazy(() => import('./pages/services/er-services'));
const UserCreate = lazy(() => import('./pages/users/UserCreate'));
const UserEdit = lazy(() => import('./pages/users/UserEdit'));
const UserIndex = lazy(() => import('./pages/users/UserIndex'));
const RedirectCreate = lazy(() => import('./pages/redirects/RedirectCreate'));
const RedirectEdit = lazy(() => import('./pages/redirects/RedirectEdit'));
const RedirectIndex = lazy(() => import('./pages/redirects/RedirectIndex'));

export default function App() {
    return (
        <>
            <Helmet>
                <title>Emergency Room : SignatureCare Emergency Center 24-Hour ER</title>
                <meta name="description" content="24-hour emergency room equipped to provide emergency care in Houston, Austin, and other TX cities. Board-certified ER physicians onsite 24/7" />
                <link rel="canonical" href="/" />
            </Helmet>

            <ScrollToTop>
                <Suspense fallback={
                    <div className='d-flex justify-content-center align-items-center min-vh-100'>
                        <Loader />
                    </div>
                }>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route element={<AdminLayout />}>
                            <Route path="/admin/dashboard" element={<Dashboard />} />

                            <Route path="/admin/pages" element={<PageIndex />} />
                            <Route path="/admin/pages/create" element={<PageCreate />} />
                            <Route path="/admin/pages/:pageSlug/edit" element={<PageEdit />} />
                            <Route path="/admin/home-page" element={<PageCreateHome />} />

                            <Route path="/admin/locations" element={<LocationIndex />} />
                            <Route path="/admin/locations/create" element={<LocationCreate />} />
                            <Route path="/admin/locations/:locationSlug/edit" element={<LocationEdit />} />

                            <Route path="/admin/sub-locations" element={<SubLocationIndex />} />
                            <Route path="/admin/sub-locations/create" element={<SubLocationCreate />} />
                            <Route path="/admin/sub-locations/:locationSlug/edit" element={<SubLocationEdit />} />
                            <Route path="/admin/location-content" element={<LocationContent />} />

                            <Route path="/admin/symptoms" element={<SymptomIndex />} />
                            <Route path="/admin/symptoms/create" element={<SymptomCreate />} />
                            <Route path="/admin/symptoms/:symptomSlug/edit" element={<SymptomEdit />} />

                            <Route path="/admin/blogs" element={<BlogIndex />} />
                            <Route path="/admin/blogs/create" element={<BlogCreate />} />
                            <Route path="/admin/blogs/:blogSlug/edit" element={<BlogEdit />} />

                            <Route path="/admin/categories" element={<CategoryIndex />} />

                            <Route path="/admin/awards" element={<AwardIndex />} />
                            <Route path="/admin/awards/create" element={<AwardCreate />} />
                            <Route path="/admin/awards/:awardID/edit" element={<AwardEdit />} />

                            <Route path="/admin/videos" element={<VideoIndex />} />
                            <Route path="/admin/videos/create" element={<VideoCreate />} />
                            <Route path="/admin/videos/:videoID/edit" element={<VideoEdit />} />

                            <Route path="/admin/job-posts" element={<JobPostIndex />} />
                            <Route path="/admin/job-posts/create" element={<JobPostCreate />} />
                            <Route path="/admin/job-posts/:jobPostSlug/edit" element={<JobPostEdit />} />
                            <Route path="/admin/active-jobs" element={<JobPostActive />} />

                            <Route path="/admin/scholarships" element={<ScholarshipIndex />} />
                            <Route path="/admin/scholarships/create" element={<ScholarshipCreate />} />
                            <Route path="/admin/scholarships/:scholarshipID/edit" element={<ScholarshipEdit />} />

                            <Route path="/admin/physicians-portal" element={<PortalIndex />} />
                            <Route path="/admin/physicians-portal/create" element={<PortalCreate />} />
                            <Route path="/admin/physicians-portal/:portalSlug/edit" element={<PortalEdit />} />

                            <Route path="/admin/media/documents" element={<DocumentIndex />} />
                            <Route path="/admin/media/images" element={<ImageIndex />} />

                            <Route path="/admin/emails" element={<EmailIndex />} />
                            <Route path="/admin/emails/create" element={<EmailCreate />} />
                            <Route path="/admin/emails/:emailID/edit" element={<EmailEdit />} />
                            <Route path="/admin/emails/job-location-list" element={<EmailJobLocationList />} />
                            <Route path="/admin/emails/entries" element={<EmailEntry />} />

                            <Route path="/admin/pages/global-tags" element={<GlobalTags />} />
                            <Route path="/admin/navigations/main-menu" element={<NavMainMenu />} />
                            <Route path="/admin/navigations/top-menu" element={<NavTopMenu />} />
                            <Route path="/admin/navigations/bottom-menu" element={<NavBottomMenu />} />
                            <Route path="/admin/navigations/footer" element={<NavFooter />} />
                            <Route path="/admin/navigations/location-sidebar" element={<NavLocationSidebar />} />
                            <Route path="/admin/navigations/symptom-sidebar" element={<NavSymptomSidebar />} />
                            <Route path="/admin/navigations/mobile-layouts" element={<NavMobileLayout />} />

                            <Route path="/admin/er-services" element={<ERService />} />

                            <Route path="/admin/redirects" element={<RedirectIndex />} />
                            <Route path="/admin/redirects/create" element={<RedirectCreate />} />
                            <Route path="/admin/redirects/:redirectId/edit" element={<RedirectEdit />} />

                            <Route path="/admin/users" element={<UserIndex />} />
                            <Route path="/admin/users/create" element={<UserCreate />} />
                            <Route path="/admin/users/:userId/edit" element={<UserEdit />} />
                        </Route>
                    </Routes>
                </Suspense>
            </ScrollToTop>
        </>
    );
}

